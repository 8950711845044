@font-face {
  font-family: 'Geomanist';
  src: url('/assets/fonts/geomanist/geomanist-regular.woff2') format('woff2'),
  url('/assets/fonts/geomanist/geomanist-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GeomanistItalic';
  src: url('/assets/fonts/geomanist/geomanist-regular-italic.woff2') format('woff2'),
  url('/assets/fonts/geomanist/geomanist-regular-italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
