@charset "utf-8";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-family: "Geomanist", sans-serif;

  --color-text-light: #c7d1d9;
  --color-text-transparent: #bcc5cc;
  --color-text-dark: #49545a;
  --color-text-darker: #334040;

  --transition: all 0.4 ease;
  --transition-1: all 0.2s ease;
  --transition-2: all 0.4s ease;
  --transition-3: all 0.6s ease;
  --transition-4: all 0.8s ease;
  --transition-5: all 1s ease;
  --transition-6: all 1.2s ease;
  --transition-7: all 1.4s ease;

  --transition-nav: var(--transition-7);
  --transition-slow: var(--transition-4);
  --topbar-height-px: 60px;
  --sidebar-width-px: 60px;

  --color-success: #4eb76e;
  --color-success-dark: rgba(79, 184, 110, 0.8);
  --color-success-darker: rgba(79, 184, 110, 0.6);

  --color-warn: #c1a317;
  --color-warn-dark: rgba(194, 163, 23, 0.8);
  --color-warn-darker: rgba(194, 163, 23, 0.6);

  --color-info: #17a2b8;
  --color-info-dark: rgba(22, 162, 184, 0.8);
  --color-info-darker: rgba(22, 162, 184, 0.6);

  --color-danger: #a3300a; // #d52500;
  --color-danger-dark: rgba(163, 48, 10, 0.8); //  rgba(214, 36, 0, 0.8);
  --color-danger-darker: rgba(163, 48, 10, 0.6); //  rgba(214, 36, 0, 0.6);

  --color-border: #f1f4f7;
  --color-border-2: #d7e0e2;
  --color-border-dark: #b0b9bf;

  --color-background-primary: #ebeff3;

  --color-background-secondary: #eef1ef;
}

@import "ngx-toastr/toastr";
@import "@ctrl/ngx-emoji-mart/picker";

@import "./styles/geomanist-font";
@import "./styles/sqs-theme";
@import "./styles/theme-overrides";

* {
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Geomanist, sans-serif;
}

.ngx-toastr {
  box-shadow: none !important;
}

// ---------- Post Form (Overlay dialog) ---------- //
.post-form-overlay-dialog {
  max-width: unset !important;
  min-width: max(600px, 40vw) !important;
  max-height: unset;
  height: 100vh;

  position: absolute !important;
  right: 0;
  top: 0;
  bottom: 0;
}

.gallery-dialog-panel {
  max-width: unset !important;
  max-height: unset !important;
  width: 100vw;
  height: 100vh;

  position: absolute !important;
  right: 0;
  top: 0;
  bottom: 0;
}

// ------------------------------------------------ //
