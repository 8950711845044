input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="datetime-local"],
select,
option {
  transition: var(--transition);
  display: block;
  width: 100%;
  height: 35px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-text-dark);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--color-border-dark);
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px #dde1e3;
}

input::placeholder {
  color: var(--color-text-transparent);
}

input[type="text"]:focus-visible,
input[type="email"]:focus-visible,
input[type="password"]:focus-visible,
input[type="datetime-local"]:focus-visible,
textarea:focus-visible,
select {
  transition: var(--transition);
  outline-color: #b0bfb5;
  outline-offset: 0;
}

h1 {
  font-size: 2.1875rem;
  font-weight: 400;
  line-height: 1.2;
  color: var(--color-text-dark);
}

a {
  transition: var(--transition);
  color: var(--color-text-dark);

  &:hover {
    transition: var(--transition);
    color: var(--color-text-darker);
  }
}

// -------------------- Material Icons -------------------- //
// Use .icon instead of .material-icons
.icon {
  font-family: "Material Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 18px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";

  &.outlined {
    font-family: "Material Icons Outlined", sans-serif !important;
  }

  &.round {
    font-family: "Material Icons Round", sans-serif !important;
  }

  &.sharp {
    font-family: "Material Icons Sharp", sans-serif !important;
  }

  user-select: none;
}
// -------------------------------------------------------- //

// -------------------- Buttons -------------------- //
.sqs-button {
  transition: all 0.8s ease;

  cursor: pointer;
  font-size: 16px;
  line-height: 1.5;

  padding: 10px 14px;
  outline: 0;
  border: 1px solid var(--color-text-transparent);
  border-radius: 8px;

  display: inline-block;
  width: fit-content;

  &,
  & * {
    color: var(--color-text-darker);
  }
  box-shadow:
    inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(73, 84, 90, 0.075);

  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.invalid {
    opacity: 0.7;
    pointer-events: none;
  }

  &.faded {
    opacity: 0.7;
  }

  &.primary {
    &,
    & * {
      color: #fff;
    }
    background-color: var(--color-info);
    border: 1px solid var(--color-info);

    &:hover {
      transition: all 0.8s ease;
      background-color: var(--color-info-dark);
    }

    &:active {
      transition: all 0.8s ease;
      background-color: var(--color-info-darker);
    }
  }

  &.secondary {
    &,
    & * {
      color: var(--color-text-darker);
    }
    background-color: var(--color-2);
    border: 1px solid var(--color-2);

    &:hover {
      transition: all 0.8s ease;
      background-color: var(--color-2-dark);
    }

    &:active {
      transition: all 0.8s ease;
      background-color: var(--color-2-darker);
    }
  }

  &.success {
    &,
    & * {
      color: #fff;
    }
    background-color: var(--color-success);
    border: 1px solid var(--color-success);

    &:hover {
      transition: all 0.8s ease;
      background-color: var(--color-success-dark);
    }

    &:active {
      transition: all 0.8s ease;
      background-color: var(--color-success-darker);
    }
  }

  &.danger {
    &,
    & * {
      color: #fff;
    }

    background-color: var(--color-danger);
    border: 1px solid var(--color-danger);

    &:hover {
      transition: all 0.8s ease;
      background-color: var(--color-danger-dark);
    }

    &:active {
      transition: all 0.8s ease;
      background-color: var(--color-danger-darker);
    }
  }

  &.warn {
    &,
    & * {
      color: #333;
    }

    background-color: var(--color-warn);
    border: 1px solid var(--color-warn);

    &:hover {
      transition: all 0.8s ease;
      background-color: var(--color-warn-dark);
    }

    &:active {
      transition: all 0.8s ease;
      background-color: var(--color-warn-darker);
    }
  }

  &.transparent {
    color: var(--color-text-darker) !important;
    background-color: transparent !important;
    border-color: var(--color-border-dark) !important;
  }

  &.light {
    &,
    & * {
      color: var(--color-text-darker) !important;
    }
    background-color: #fff !important;
    border-color: var(--color-border-dark) !important;
  }

  &.black,
  &.dark {
    &,
    & * {
      color: #fff !important;
    }
    background-color: var(--color-text-dark) !important;
    border-color: var(--color-text-dark) !important;
  }
}

button.btn-primary {
  cursor: pointer;
  display: inline-block;
  width: fit-content;

  padding: 0.5rem 1rem;
  //font-size: 1.09375rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  border-radius: 999em;

  //&, & * { color: #fff; }
  color: white !important;
  background-color: #4eb76e !important;
  border: 1px solid #4eb76e;
  box-shadow:
    inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(73, 84, 90, 0.075);

  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.2s ease-in-out; // color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    background-color: #409e5d;
    border-color: #3d9558;
  }

  &.transparent {
    color: var(--color-text-darker) !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  &.light {
    color: var(--color-text-darker) !important;
    background-color: #fff !important;
    border-color: #fff !important;
  }

  &.danger {
    background-color: var(--color-danger) !important;
    border-color: var(--color-danger) !important;
  }

  &.faded {
    opacity: 0.6;
  }
}
// ------------------------------------------------- //

// -------------------- [appLoading] directive -------------------- //
.is-loading {
  pointer-events: none;
  position: relative;
  background-color: inherit;

  &:after {
    box-sizing: border-box;
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    opacity: 0.7;
    z-index: 199;
  }

  &:before {
    box-sizing: border-box;
    position: fixed;
    content: "";

    border-radius: 50%;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    z-index: 200;

    border-width: 3px;
    border-style: solid;
    border-right: 3px solid transparent !important;

    animation: rotate 0.7s cubic-bezier(0.51, 0.57, 0.6, 0.62) infinite;
  }
}

button.is-loading {
  color: transparent !important;

  &:before {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-width: 2px;
    border-color: white;
    border-right: 2px solid transparent !important;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
// ---------------------------------------------------------------- //

.sqs-page {
  padding: 45px 45px 20px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  height: 100%;
  width: 100%;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.45rem;
  }
  &::-webkit-scrollbar-thumb {
    width: 0.45rem;
    border-radius: 12px;
    background: rgba(51, 51, 51, 0.6);
  }
}
