// ----- Topnav / Menu items ----- //
.topNavMenuOptionBtn {
  &:focus-visible {
    outline: 0 !important;
  }

  &.brand i.icon {
    font-size: 24px;
  }

  i.icon.hidden {
    visibility: hidden;
  }
}

.mat-mdc-menu-item-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  i.icon {
    font-size: 16px;
    line-height: 18px;
  }

  span {
    line-height: 18px;

    &.text {
      font-size: 16px;
      font-weight: 500;
    }

    &.text-small {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
// ------------------------------- //

// ----- Basic confirmation dialog ----- //
.confirmation-dialog-component {
  max-width: 600px !important;
}
// ------------------------------------- //

// ----- Media Library / Directory tree ----- //
.media-dir-tree {
  .mat-tree-node button.mdc-icon-button.mat-mdc-icon-button {
    padding: 4px;
  }
}
// ------------------------------------------ //

// ----- Ng Select ----- //
ng-select.ng-select .ng-dropdown-panel/*.ng-select-bottom*/ .ng-dropdown-panel-items.scroll-host {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.45rem;
  }
  &::-webkit-scrollbar-thumb {
    width: 0.45rem;
    border-radius: 12px;
    background: rgba(51, 51, 51, 0.6);
  }
}
ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0px !important;
}
ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: unset !important;
}
// --------------------- //

// ----- Material Radio "selected" color ----- //
.mat-accent {
  --mdc-radio-selected-focus-icon-color: var(--color-info) !important;
  --mdc-radio-selected-hover-icon-color: var(--color-info) !important;
  --mdc-radio-selected-icon-color: var(--color-info) !important;
  --mdc-radio-selected-pressed-icon-color: var(--color-info) !important;
  --mat-radio-checked-ripple-color: var(--color-info) !important;
}

.mat-mdc-checkbox-checked {
  .mdc-checkbox__background {
    border-color: var(--color-info) !important;
    background-color: var(--color-info) !important;
  }
}
